import { useQuery } from "@apollo/client"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-react-intl"
import React, { useContext, useState } from "react"
// import { HomePageContent } from "./HomePageContent"
import { CircularProgress } from "@mui/material"

// import * as S from "./style"
import { AuthContext } from "../../../../containers/AuthContext"
import { useIntlPathName } from "../../../../hooks/useIntlPathName"
import { HomeLayout, Tab } from "../HomeLayout"
import { userResourceListQuery } from "../../user/queries"
import { userQuery } from "../../auth/LoginPage/queries"
import { ManagersPageContent } from "./ManagersPageContent"

const ManagersPage: React.FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const pathname = useIntlPathName()

  const handleTabChange = (tab: Tab) => {
    const query = queryString.parse(location.search)
    navigate(
      queryString.stringifyUrl({
        url: tab.path,
        query,
      })
    )
  }

  return (
    <>
      <HomeLayout
        variant={"default"}
        activeTab="organisations"
        onTabChange={handleTabChange}
      >
        <ManagersPageContent />
      </HomeLayout>
    </>
  )
}
export { ManagersPage }
