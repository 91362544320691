import { FormattedMessage } from "gatsby-plugin-react-intl"
import React from "react"
import { ItemLinkTile } from "../../../molecules/ItemLinkTile"

import * as S from "./style"

const ManagersPageContent: React.FC = () => {
  return (
    <S.Main>
      <S.Wrapper>
        <S.Header>
          <FormattedMessage
            defaultMessage="App / Organizations learning planning"
            description="information"
          />
        </S.Header>
        <S.Description>
          <FormattedMessage
            defaultMessage="Send message at: {email}"
            description="information"
            values={{
              email: <b>contact@learntray.com</b>,
            }}
          />
        </S.Description>
      </S.Wrapper>
    </S.Main>
  )
}

export { ManagersPageContent }
