import styled from "styled-components"

export const Main = styled.main`
  padding: 24px;
  width: 100%;
`

export const Wrapper = styled.div`
  margin: 150px 0 50px 0;

  @media screen and (min-width: 800px) {
    margin: 180px 10% 50px 10%;
  }
  @media screen and (min-width: 1200px) {
    margin: 180px 20% 50px 20%;
  }
`

export const Header = styled.h2`
  text-align: center;
  font-size: 1.2rem;
  margin: 0 0 10px 0;

  @media screen and (min-width: 800px) {
    font-size: 1.5rem;
    margin: 0 0 30px 0;
  }
`

export const Subheader = styled.h3`
  text-align: center;
  font-size: 1rem;
  margin: 0 0 10px 0;

  @media screen and (min-width: 800px) {
    font-size: 1.2rem;
    margin: 0 0 30px 0;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 50px;
`

export const ItemList = styled.div`
  margin: 20px 0 0 0;
  display: grid;
  gap: 20px;
`

export const Description = styled.p`
  text-align: center;
  font-size: 1rem;
  margin: 0 0 10px 0;

  @media screen and (min-width: 800px) {
    font-size: 1.2rem;
    margin: 0 0 30px 0;
  }
`
